<template>
  <div class="page-wrapper-pc">
    <div class="container">
      <div class="size-28 weight-600 text-center padding-top-40">서비스 개설 신청이 완료되었습니다.</div>
      <div class="font-text2 text-center margin-top-8">약 30분 내로 개설이 완료되며, 완료 시 관리자 이메일로 안내 드립니다.<br>
        자세한 내용은 내 서비스 내역에서 현황을 확인할 수 있습니다.</div>
      <div class="service-info-box1 margin-top-40">
        <div class="size-18 weight-600 margin-bottom-32">신청내용</div>
        <div class="size-16 font-text2">서비스 개설 정보</div>
        <div class="size-16 flex-between" style="margin-top:4px">
          <div>{{ info.name }}<br><span class="size-14">{{ info.key }}@launchpack.co.kr</span></div>
          <div class="flex-align">
            <div class="bullet"></div>
            <div>개설 진행 중</div>
            </div>
        </div>
      </div>
      <div class="position-relative" style="padding:2px 26px;background-color:white">
        <div class="divide-dashed"></div>
      </div>
      <div class="service-info-box2">
        <div class="flex">
          <div class="col-6">
            <div class="size-18 weight-600">관리자 정보</div>
            <div class="size-16 flex-align margin-top-40">
              <div class="font-text2" style="width:85px">성함</div>
              <div>{{ user.name }}</div>
            </div>
            <div class="flex-align">
              <div class="size-16 font-text2" style="width:85px">연락처</div>
              <div>{{ user.phone }}</div>
            </div>
            <div class="flex-align">
              <div class="size-16 font-text2" style="width:85px">이메일</div>
              <div>{{ user.email }}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="size-18 weight-600">결제 정보</div>
            <div class="size-16 flex-align margin-top-40">
              <div class="font-text2" style="width:120px">총 결제 금액</div>
              <div>{{ info.price | currency }}</div>
            </div>
            <div class="size-16 flex-align">
              <div class="font-text2" style="width:120px">결제 수단</div>
              <div>카드결제 / {{ info.credit }}</div>
            </div>
          </div>
        </div>

        <div class="flex-end" style="margin-top:20px">
          <!--<button class="button" style="margin-right:12px">결제 내역 상세보기</button>-->
          <button class="button is-primary" @click="$router.replace('/mypage/my_service')">내 서비스 목록</button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  export default {
    name: "ServiceRequestDone",
    created() {
      this.info = this.$store.getters.accountDone;
      if(!this.info) {
        this.toast('올바른 접근이 아닙니다');
        this.$router.back();
      }
      this.$store.commit('setAccountDone', undefined);
    },
    data() {
      return {
        info: undefined
      }
    },
    methods: {}
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .divide-dashed
    border-top 2px dashed $border2
  .service-info-box1
  .service-info-box2
    background-color white
    padding 28px

  .service-info-box1
    box-shadow 0 0 8px 5px rgba(0,0,0, 0.1)
  .service-info-box2
    box-shadow 0 3px 8px 3px rgba(0,0,0, 0.1)

  .bullet
    background-color #FDC222
    border-radius 100%
    width 8px
    height 8px
    margin-right 8px
</style>
